<template src="./review.html"></template>
<style scoped src="./review.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import TransactionService from '@/services/TransactionService.vue'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import moment from 'moment'
export default {
    data () {
        return {
            externalReference : '',
            customerEmail : '',
            transactionId : '',
            resdata :{
                requestid:'',
                notes: [],
            },
           currentPage: 1,
           perPage : 8,
           xmlData: null,
           notes: '',
           customerNumber:'',
           loaderVisible :false,
           recipientList : [],
           Utils : Utils,
           alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: [
                {key: 'CreateDateUtc', label: 'Created Date'},
                {key: 'CreatedBy', label: 'Created by'},
                {key: 'Note', label: 'Note' }
            ]
        }
    },
    components : {
        Loading, modal
    },
    methods : {
        async handleAddNotesEvent(){
            try {
                this.loaderVisible = true
                await TransactionService.methods.AddTransactionNotes(this.notes,this.resdata.referenceNumber)
                .then( () => {
                    this.updatesNotesTable()
                    this.showAlert(1, 'Notes added Successfully')
                    this.notes = ''    
                })
                .catch( err => {         
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)                        
                })
                this.loaderVisible = false
            } catch (error) {
                this.alertObject = error
                this.$bvModal.show(this.$refs.childModal.id)  
            }
        },
        async updatesNotesTable() {
            await TransactionService.methods.GetTransactionDeatils({
                CustomerEmail : this.customerEmail,
                TransactionId : this.transactionId
            })
            .then(async res=> {
                this.resdata.notes = await this.mapNotes(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote)
            }).
            catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        mapNotes(notes) {
            let returnNotes = []
            if(notes) {
                notes = Array.isArray(notes) ? notes : [notes]
                notes.map(obj=> {
                    Object.keys(obj).map(key => {
                        obj[key] = obj[key]?._text
                        if(key === 'CreateDateUtc') 
                            obj[key] = Utils.methods.UTCtoLocal(moment(obj[key]).format('MM/DD/YYYY HH:mm:ss'))
                    })
                })
                returnNotes = notes
            }
            return returnNotes
        },
        async completeETransfer(){
            try {
                this.loaderVisible  = true
                await TransactionService.methods.CompleteETransfer({
                    CustomerEmail :this.customerEmail,
                    ReferenceNumber:this.resdata.referenceNumber,
                    TransactionId:this.transactionId,
                    TransactionTotal:this.resdata.sourceAmount
                })
                .then(async res => {
                    if(res.csEtransferCompleteResponse.SendMoneyRequest && Object.keys(res.csEtransferCompleteResponse.SendMoneyRequest).length > 0){
                        if(res.csEtransferCompleteResponse.PromoCodeData?.Code){
                            if(res.csEtransferCompleteResponse.PromoCodeData.Code && res.csEtransferCompleteResponse.PromoCodeData.Issue) {
                                await TransactionService.methods.RedeemPromoCode({
                                    CustomerEmail : this.customerEmail,
                                    Code : res.csEtransferCompleteResponse.PromoCodeData.Code._text,
                                    IssueNumber : res.csEtransferCompleteResponse.PromoCodeData.Issue._text,
                                    ValidateOnly : 'false',
                                    ParentReferenceNumber : this.resdata.referenceNumber,
                                    RemittanceGateway : res.csEtransferCompleteResponse.PromoCodeData.RemittanceGateway._text,
                                    Amount : res.csEtransferCompleteResponse.SendMoneyRequest.XferAmount._text
                                })
                                .then(async () => {
                                    await this.sendMoney(res.csEtransferCompleteResponse.SendMoneyRequest.OriginCurrency._text,
                                    res.csEtransferCompleteResponse.SendMoneyRequest.RecipientId._text,
                                    res.csEtransferCompleteResponse.SendMoneyRequest.XferAmount._text,
                                    this.customerEmail,
                                    res.csEtransferCompleteResponse.SendMoneyRequest.SessionId._text,
                                    res.csEtransferCompleteResponse.SendMoneyRequest.Purpose._text,
                                    this.resdata.referenceNumber,
                                    res.csEtransferCompleteResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                                })
                                .catch(err => {
                                    this.alertObject = err
                                    this.$bvModal.show(this.$refs.childModal.id)
                                })
                            }else{
                                 await this.sendMoney(res.csEtransferCompleteResponse.SendMoneyRequest.OriginCurrency._text,
                                 res.csEtransferCompleteResponse.SendMoneyRequest.RecipientId._text,
                                 res.csEtransferCompleteResponse.SendMoneyRequest.XferAmount._text,
                                 this.customerEmail,
                                 res.csEtransferCompleteResponse.SendMoneyRequest.SessionId._text,
                                 res.csEtransferCompleteResponse.SendMoneyRequest.Purpose._text,
                                 this.resdata.referenceNumber,
                                 res.csEtransferCompleteResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                            }
                        }else {
                            await this.sendMoney(res.csEtransferCompleteResponse.SendMoneyRequest.OriginCurrency._text,
                            res.csEtransferCompleteResponse.SendMoneyRequest.RecipientId._text,
                            res.csEtransferCompleteResponse.SendMoneyRequest.XferAmount._text,
                            this.customerEmail,
                            res.csEtransferCompleteResponse.SendMoneyRequest.SessionId._text,
                            res.csEtransferCompleteResponse.SendMoneyRequest.Purpose._text,
                            this.resdata.referenceNumber,
                            res.csEtransferCompleteResponse.SendMoneyRequest.RequestedDestinationAmount._text)
                        }
                    }else{
                        this.showAlert(null, 'Invalid Transaction Details. Not able to process E-Transfer Compliance.')
                    }
                    this.loaderVisible  = false
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)  
                    this.loaderVisible  = false
                })
                let context = this
                setTimeout(function(){ 
                    context.goBack() 
                }, 3000)
               
            } catch (error) {
                this.loaderVisible  = false
            }
        },
        async sendMoney(originCurrency,recipientId,xferAmount,customerEmail,mrmssession,purpose,parentReferenceNumber, requestedDestinationAmount){
            if(this.isDisabledBank(customerEmail,recipientId)){
                this.showAlert(null, "Recipient's bank are temporarily disabled. The bank's system is under maintenance.")
                return
            }
            await TransactionService.methods.SendMoney({
                OriginCurrency : originCurrency,
                RecipientId : recipientId,
                XferAmount : xferAmount,
                CustomerEmail : customerEmail,
                Purpose : purpose,
                ParentReferenceNumber : parentReferenceNumber,
                SessionId : mrmssession,
                RequestedDestinationAmount : requestedDestinationAmount
            })
            .then(res => {
               this.$router.push({
                    name: 'Confirmation',
                    params: {
                        resSendMoney : res.csSendMoneyResponse.TransactionData,
                        parentReferenceNumber : res.csSendMoneyResponse.ParentReferenceNumber
                    }
                })
            })
            .catch(err =>{
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })

        },
        isDisabledBank(customerEmail,recipientId){
            try {
                let recipientInfo = this.recipientList.find(el=> el.Id === recipientId)
                if(recipientInfo){
                    if(recipientInfo.RemittanceGatewayId === '2'){
                        let customProperties = recipientInfo.CustomProperties
                        customProperties = this.removeUnderscoreText(customProperties)
                        let bankCode = customProperties.find(el=> el.Name === 'bankCode' )
                        if(bankCode){
                            //check with disabled bank codes list if it contain return true
                        }
                    }
                }
                return false
            } catch (error) {
                //
            }
        },
        removeUnderscoreText(data){
            if(data) {
                if(!Array.isArray(data)){
                    data=[data]
                }
                const reciptient = (recipt) => {
                    Object.values(recipt).map((el, key) => {
                        const Objectkey = Object.keys(recipt)[key]
                        recipt[Objectkey] = el._text
                    })
                }
                data.map(reciptient)
            }else{
                data = data ? data : []
            }   
            return data
        },
        goBack () {
            this.$router.push({
                name : 'CompleteETransfer',
                params: {
                    filters : this.$route.params.filters
                }
            })
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    computed : {
      rows () {
            return this.resdata.notes?.length
       }
    },
    async mounted() {
        try {
            this.customerEmail = this.$route.params.email
            this.transactionId = this.$route.params.Transactionid
            this.loaderVisible=true
            TransactionService.methods.GetRecipientsDetails(this.customerEmail,'','')
            .then(res => {
                this.recipientList = res.csGetRecipientsResponse.RecipientItems.RecipientItem
                this.recipientList = this.removeUnderscoreText(this.recipientList)
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
            await TransactionService.methods.GetTransactionDeatils({
                CustomerEmail : this.customerEmail,
                TransactionId : this.transactionId
            })
            .then(async res => {
                this.resdata.parentReferenceNumber = res.csTransactionDetailsResponse.TransactionData.ParentReferenceNumber._text
                this.resdata.requestid=res.csTransactionDetailsResponse.RequestId?._text
                this.resdata.customerEmail=res.csTransactionDetailsResponse.TransactionData.SenderEmail._text
                this.resdata.transactionNumber=res.csTransactionDetailsResponse.TransactionData.TransactionNumber._text
                this.resdata.referenceNumber=res.csTransactionDetailsResponse.TransactionData.ReferenceNumber._text
                this.resdata.dateAndTime = Utils.methods.UTCtoLocal(moment(moment(res.csTransactionDetailsResponse.TransactionData.DateTime._text, 'MM/DD/YYYY HH:mm:ss')._d).format( 'MM-DD-YYYY HH:mm:ss'))
                this.resdata.transactionType=res.csTransactionDetailsResponse.TransactionData.TransactionType._text
                this.resdata.destinationAmount=res.csTransactionDetailsResponse.TransactionData.DestAmount._text
                this.resdata.requestedAmount=res.csTransactionDetailsResponse.TransactionData.RequestedAmount._text
                this.resdata.serviceCharge=res.csTransactionDetailsResponse.TransactionData.ServiceCharge._text
                this.resdata.sourceAmount=res.csTransactionDetailsResponse.TransactionData.SourceAmount._text
                this.resdata.phoneNumber=res.csTransactionDetailsResponse.TransactionData.PhoneNumber._text
                this.resdata.notes = await this.mapNotes(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote)
                // this.resdata.notes = Object.keys(res.csTransactionDetailsResponse.TransactionNotes).length > 0 ? Array.isArray(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote) ? (res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote).map(s => ({ CreateDateUtc: s.CreateDateUtc._text, CreatedBy: s.CreatedBy._text, Notes: s.Note._text })) : [{
                //     CreateDateUtc : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreateDateUtc._text,
                //     CreatedBy  : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreatedBy._text,
                //     Notes  :  res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.Note._text
                // }] : []
                this.xmlData=res.csTransactionDetailsResponse.TransactionData.RemittanceData._text
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)   

                
                this.loaderVisible=false
            })
            // let remittanceData=xmltojson.methods.getParseObjectFromXml(this.xmlData)
            // this.resdata.isCustomerReliable=remittanceData.MoneyTransferRequestSavedData.MrmsCallData.CustomerIsReliable._text.toLowerCase()
            // AccountService.methods.GetAccountDetails(this.customerEmail)
            // .then(res => {
            //     this.customerNumber=res.csGetUserProfileResponse.UserRefId?._text
            // })
            // .catch(err => {
            //     this.alertObject = err
            //     this.$bvModal.show(this.$refs.childModal.id)
            //     this.loaderVisible=false
            // })
            this.loaderVisible=false
        } catch (error) {
            this.loaderVisible = false
        }
    }
}
</script>
